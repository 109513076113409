<template>
    <div class="ml-3 mt-3">
        <p class="va-h6">Total: {{ totalPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</p>
    </div>
    <va-card class="row" style="margin: 20px 0;justify-content: center;">
        <va-card-content>
            <va-data-table class="table-crud" :no-data-html="noDataHTML" :items="categorySummary" :columns="columns" :loading="loading" hoverable>
                <template #cell(category)="{ rowData }">
                    {{ rowData.name }}
                </template>
                <template #cell(warehouse)="{ rowData }">
                    {{ rowData.subWarehouse.name }}
                </template>
                <template #cell(price)="{ rowData }">
                    {{ rowData.totalPrice ? rowData.totalPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00' }}
                </template>
            </va-data-table>
        </va-card-content>
    </va-card>
</template>
<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'

    import type { CategoryDTO } from '@/models/warems.models'

    import reportService from '@/services/report-service'

    const categorySummary = ref([] as CategoryDTO[])
    const columns = [
        { key: 'category' },
        { key: 'warehouse' },
        { key: 'price' }
    ]
    const loading = ref(true)
    const noDataHTML = ref('Loading...')

    const totalPrice = computed(() => {
        return categorySummary.value.map(c => c.totalPrice ? c.totalPrice : 0).reduce((a, b) => a+b, 0)
    }) 

    onBeforeMount(async() => {
        categorySummary.value = await reportService.getCategoryReport()
        loading.value = false
        noDataHTML.value = 'No Data'
    })
</script>