<template>
    <div class="mb-2 mt-2 mr-3 ml-3" style="display: flex;justify-content: space-between;">
        <div class="va-spacing-x-3">
            <va-input v-model="filter" @update:modelValue="debouncedFilterUpdated" placeholder="filter..."/>
            <va-button @click="createProduct">Create new Product</va-button>
        </div>
        <div class="va-spacing-x-3">
            <va-button preset="secondary" @click="showColumns=true">
                <va-icon name="view_column"/> Columns
            </va-button>
            <va-button :preset="showFilter ? 'none' : 'secondary'" @click="openFilterModal">
                <va-icon name="filter_alt"/> Filter
            </va-button>
            <va-button preset="secondary" @click="downloadInventoryExcel">
                <va-icon name="download"/> Excel
            </va-button>
        </div>
    </div>

    <va-data-table class="customize-data-table" :items="products" :columns="columns" :loading="loading" :no-data-html="noDataHTML" 
        :row-bind="getRowBind" :per-page="itemsPerPage" :current-page="currentPage" sticky-header hoverable>
        <template #cell(productName)="{ rowData }">
            <a :href="`/in/settings/product/edit?id=${encodeURIComponent(rowData.productId)}&page=1`" style="color: blueviolet;">
                {{ rowData.tertiaryName ? `${rowData.secondaryName} - ${rowData.tertiaryName}` : rowData.secondaryName }}
            </a>
        </template>

        <template #cell(value)="{ rowData }">
            {{ rowData.totalWholesalePrice ? rowData.totalWholesalePrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : null }}
        </template>

        <template #cell(supplier)="{ rowData }">
            {{ rowData.brand ? rowData.brand.name : null }}
        </template>

        <template #cell(category1)="{ rowData }">
            {{ rowData.category ? rowData.category.name : null }}
        </template>

        <template #cell(category2)="{ rowData }">
            {{ rowData.subCategory ? rowData.subCategory.name : null }}
        </template>

        <template #cell(lastIn)="{ rowData }">
            {{ rowData.lastIn ? new Date(rowData.lastIn*1).toLocaleDateString() : null }}
        </template>

        <template #cell(price)="{ rowData }">
            {{ rowData.price ? rowData.price.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : null }}
        </template>

        <template #cell(wholesalePrice)="{ rowData }">
            {{ rowData.wholesalePrice ? rowData.wholesalePrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : null }}
        </template>

        <template #cell(category)="{ rowData }">
            {{ rowData.category ? rowData.category.name : null }}
        </template>

        <template #cell(weight)="{ rowData }">
            <span v-if="rowData.hasWeight === 'Weighting'" preset="outline" style="color: blueviolet;cursor: pointer;" 
                @click="updateWeightModal.show=true;updateWeightModal.weight=rowData.totalWeight;updateWeightModal.productId=rowData.productId">
                {{ rowData.totalWeight ? (rowData.totalWeight).toFixed(2) : 0 }}
            </span>
            <span v-else>
                {{ rowData.totalWeight ? (rowData.totalWeight).toFixed(2) : null }}
            </span>
        </template>

        <template #cell(action)="{ rowData }">
            <va-button class="mr-3" @click="goToHistory(rowData)" icon="history" preset="plain">History</va-button>
            <va-button preset="plain" icon="delete" @click="deleteProduct(rowData)" />
        </template>

        <template #cell(quantity)="{ rowData }">
            <a v-if="rowData.quantity" :href="`/in/inventory/details?pid=${encodeURIComponent(rowData.productId)}&weight=${rowData.hasWeight}`" style="color: blueviolet;">
                {{ rowData.quantity }}
            </a>
            <span v-else>{{ rowData.hasWeight === 'Weighting' ? 'N/A' : 0 }}</span>
        </template>

        <template #cell(reserved)="{ value, row }">
            <va-value #default="doShowInput" v-if="Utils.isAdminRole()">
                <va-input v-if="doShowInput.value" :model-value="value"
                    @blur="doShowInput.value = false" @vue:mounted="$event.component.ctx.focus()"
                    @change="($event) => updateReserveVal($event.target.value, row.rowData.productId)"
                />
                <span class="table-inline__item" v-show="!doShowInput.value" @click="doShowInput.value = true">
                    {{ value }}
                </span>
            </va-value>
            <span v-else>{{ value }}</span>
        </template>

        <template #bodyAppend>
            <tr>
                <td colspan="12">
                    <div class="flex mt-5">
                        <va-pagination v-model="currentPage" :pages="totalPages" :visible-pages="10" class="justify-center sm:justify-start" />
                    </div>
                </td>
            </tr>
        </template>
    </va-data-table>

    <va-modal v-model:model-value="showFilter" @ok="applyFilter" @cancel="closeFilter">
        <p class="va-h6">Filter by Category</p>
        <va-card-content>
            <va-tree-view v-model:checked="selectedNodes" :nodes="categoryNodes" selectable expand-all/>
        </va-card-content>
    </va-modal>

    <va-modal v-model:model-value="showColumns" @ok="applyColumnsChange">
        <p class="va-h6">Show Columns</p>
        <va-card-content>
            <va-tree-view v-model:checked="selectedColumns" :nodes="columnsNodes" selectable expand-all/>
        </va-card-content>
    </va-modal>

    <va-modal v-model:model-value="updateWeightModal.show" @ok="updateWeight" @cancel="resetUpdateWeightModal" size="small">
        <p class="va-h5 mb-5">Update Weight</p>
        <va-input v-model="updateWeightModal.weight" type="number" step="0.01" label="Weight" style="max-width: 100px;"/>
    </va-modal>
</template>

<script setup lang="ts">
    import { ref, onBeforeMount, computed } from 'vue'
    import { debounce } from 'lodash'
    import { useModal } from 'vuestic-ui'

    import type Product from '@/types/Product'
    import type { SubWarehouseDTO } from '@/models/warems.models'

    import inventoryService from '@/services/inventory-service'
    import productService from '@/services/product-service'
    import warehouseService from '@/services/warehouse-service'
    import preferenceService from '@/services/preference-service'
    import Utils from '@/utils/utils'
    import { useLoadingStore } from '@/stores/loading-store'

    const currentPage = ref(1)
    const itemsPerPage = ref(50)
    const filter = ref()
    const showFilter = ref(false)
    const showColumns = ref(false)
    const products = ref([] as Product[])
    const columns = ref([])
    const selectedNodes = ref([] as string[])
    const selectedColumns = ref([] as string[])
    const categoryNodes = ref([])
    const columnsNodes = ref([])
    const subWarehouses = ref([] as SubWarehouseDTO[])
    const loading = ref(true)
    const { confirm } = useModal()
    const { showLoading, hideLoading } = useLoadingStore()
    const updateWeightModal = ref({
        show: false,
        weight: 0,
        productId: null
    })
    const noDataHTML = ref('Loading')

    let selectedNodesBackup = [] as string[]
    let productsBackup = [] as Product[]

    const totalPages = computed(() => {
        return Math.ceil(products.value.length / itemsPerPage.value)
    })

    onBeforeMount(async() => {
        preloadColumns()

        const res = await inventoryService.getAllInventories()
        subWarehouses.value = await warehouseService.findAllSubWarehouse()

        if (res.data) {
            products.value.push(...res.data.list)

            for (let product of products.value) {
                if (product.subWarehouse && product.subWarehouse.name) product.warehouse = product.subWarehouse.name
            }

            productsBackup = JSON.parse(JSON.stringify(products.value))
        }

        const cachedInvFilter = localStorage.getItem('cached_inv_filter')
        if (cachedInvFilter) selectedNodes.value = JSON.parse(cachedInvFilter)

        preloadFilter(subWarehouses.value, cachedInvFilter != null)
        if (cachedInvFilter != null) applyFilter()
        loading.value = false
        noDataHTML.value = 'No Data'
        preNavigate()
    })

    const preloadFilter = (subWarehouses: SubWarehouseDTO[], filterCahced: boolean) => {
        subWarehouses.forEach(subWarehouse => {
            const children: any = []
            subWarehouse.categories?.forEach(category => {
                let id = subWarehouse.name + '|' + category.name

                if (!filterCahced) {
                    if (category.subCategories && category.subCategories.length) {
                        for (let subCategory of category.subCategories) {
                            selectedNodes.value.push(id + ('|' + subCategory.name))
                        }
                    } else {
                        selectedNodes.value.push(id)
                    }
                }
                
                const child = {id, label: category.name}

                if (category.subCategories) {
                    const subCategoryChild = []
                    category.subCategories.forEach(subCategory => {
                        if (!filterCahced) selectedNodes.value.push(id + '|' + subCategory.name)
                        subCategoryChild.push({id: id + '|' + subCategory.name, label: subCategory.name})
                    })
                    child.children = subCategoryChild
                }

                children.push(child)
            })
            
            categoryNodes.value.push({id: subWarehouse.name, label: subWarehouse.name, children: children})
        })
    }

    const preloadColumns = async() => {
        resetColumns()
        const inventoryColumns = await preferenceService.getInventoryColumns()
        const children: any = []

        for (let column of Object.keys(inventoryColumns)) {
            children.push({id: column, label: column})

            if (inventoryColumns[column]) selectedColumns.value.push(column)
        }

        if (selectedColumns.value.indexOf('warehouse') > -1) columns.value.push({ key: 'warehouse' })
        if (selectedColumns.value.indexOf('category1') > -1) columns.value.push({ key: 'category1' })
        if (selectedColumns.value.indexOf('category2') > -1) columns.value.push({ key: 'category2' })
        if (selectedColumns.value.indexOf('quantity') > -1) columns.value.push({ key: 'quantity' })
        if (selectedColumns.value.indexOf('weight') > -1) columns.value.push({ key: 'weight' })
        if (selectedColumns.value.indexOf('supplier') > -1) columns.value.push({ key: 'supplier' })
        if (selectedColumns.value.indexOf('location') > -1) columns.value.push({ key: 'location' })
        if (selectedColumns.value.indexOf('price') > -1) columns.value.push({ key: 'price' })
        if (selectedColumns.value.indexOf('wholesalePrice') > -1) columns.value.push({ key: 'wholesalePrice' })
        if (selectedColumns.value.indexOf('booked') > -1) columns.value.push({ key: 'booked' })
        if (selectedColumns.value.indexOf('reserved') > -1) columns.value.push({ key: 'reserved' })
        if (selectedColumns.value.indexOf('gtin') > -1) columns.value.push({ key: 'gtin' })
        if (selectedColumns.value.indexOf('code') > -1) columns.value.push({ key: 'code' })
        if (selectedColumns.value.indexOf('lastIn') > -1) columns.value.push({ key: 'lastIn' })
        if (selectedColumns.value.indexOf('action') > -1) columns.value.push({ key: 'action' })

        columnsNodes.value.push({id: null, label: 'Columns', children: children})
    }

    const preNavigate = () => {
        const currentRow = Utils.getPreselectRow()
        if (currentRow) {
            Utils.goToElement(`product-${currentRow.id}`)
        }
    }

    const updateReserveVal = async (reservedVal: number, productId: string) => {
        await productService.updateProduct({product: {productId, reserved: reservedVal}})
        window.location.reload()
    }

    const openFilterModal = () => {
        showFilter.value = true
        selectedNodesBackup = JSON.parse(JSON.stringify(selectedNodes.value))
    }

    const applyFilter = () => {
        products.value = []
        productsBackup.forEach(product => {
            if (product.category && product.subWarehouse) {
                if (product.subCategory) {
                    const id = product.subWarehouse.name + '|' + product.category.name + '|' + product.subCategory.name
                    if (selectedNodes.value.includes(id)) {
                        products.value.push(product)
                    }
                } else {
                    const id = product.subWarehouse.name + '|' + product.category.name
                    if (selectedNodes.value.includes(id)) {
                        products.value.push(product)
                    }
                }
            }
        })
        localStorage.setItem('cached_inv_filter', JSON.stringify(selectedNodes.value))
    }

    const closeFilter = () => {
        selectedNodes.value = selectedNodesBackup
        showFilter.value = false
    }

    const getRowBind = (row: any) => {
        let rowClass = null

        if (row.reorderPoint && row.reorderPoint != 0 && row.quantity < row.reorderPoint) {
            rowClass = 'insufficient-inventory'
        }
        
        return {
          id: 'product-' + row.productId,
          class: rowClass
        }
    }

    const filterUpdated = (filter: string) => {
        if (!filter || filter.trim().length === 0) {
            products.value = [...productsBackup];
        } else {
            products.value = productsBackup.filter(p => 
                p.secondaryName.toLocaleLowerCase().includes(filter.trim().toLocaleLowerCase())
            );
        }
    }

    const debouncedFilterUpdated = debounce(filterUpdated, 500)

    const applyColumnsChange = () => {
        resetColumns()
        let inventoryColumns = {}

        for (let column of selectedColumns.value) {
            columns.value.push({ key: column })
            inventoryColumns[column] = true
        }

        preferenceService.updateInventoryColumns(inventoryColumns)
    }

    const createProduct = () => {
        window.location.href='/in/settings/product/new'
    }

    const resetColumns = () => {
        columns.value = [{ key: 'productName' }]
    }

    const deleteProduct = (product: any) => {
        confirm({message: 'Are you sure you want to delete "' + (product.tertiaryName ? product.tertiaryName : product.secondaryName) + '"?'})
            .then(async(ok) => {
            if (!ok) return
            try {
                await productService.deleteProduct(product.productId)
                confirm({message: 'Product deleted.', cancelText: ''}).then(() => { window.location.reload() })
            } catch(err: any) {
                confirm({ message: err, cancelText: '' }).then(() => { window.location.reload() })
            }
        })
    }

    const downloadInventoryExcel = async() => {
        showLoading()
        const response = await inventoryService.downloadInventoryExcel()
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL)
        hideLoading()
    }

    const goToHistory = (rowData) => {
        window.location.href = `/in/inventory/history?pid=${encodeURIComponent(rowData.productId)}`
    }

    const resetUpdateWeightModal = () => {
        updateWeightModal.value.show = false
        updateWeightModal.value.weight = 0
    }

    const updateWeight = async() => {
        if (await productService.updateProductWeight({ product: { productId: updateWeightModal.value.productId, totalWeight: updateWeightModal.value.weight } })) {
            Utils.setPreselectRow(decodeURIComponent(updateWeightModal.value.productId), 1)
            window.location.reload()
        }
    }
</script>
<style>
    .insufficient-inventory {
        background-color: darkorange;
    }
</style>